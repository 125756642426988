













import { Component, Vue } from "vue-property-decorator"
import Cookies from "js-cookie"
import balloons from "@/components/balloons.vue"
import integralLack from "@/components/integralLack.vue";

//
@Component({
	components: {
		balloons,
		integralLack
	}
})
export default class App extends Vue {
	isToken: boolean = false
	async created() {
		console.log(window.location)
		console.log(this.$store.state.isToken)
		if (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("InvitingUsers") > -1) {
			this.isToken = true
		}
		const userAgent: string = navigator.userAgent.toLowerCase()
		if (userAgent.indexOf("eshebao") != -1||userAgent.indexOf("xiaoyiduoduo") != -1) {
			;(window as any).flashToken = this.flashToken
			this.flashToken()
		} else {
			if (Cookies.get("login_token") != null||Cookies.get("userInfostr") != null) {	
				let loin_token : any= Cookies.get('login_token');
                let userInfostr: any= Cookies.get('userInfostr');
				let token: any	
				if(loin_token) {
					 token = this.b64DecodeUnicode(Cookies.get("login_token"))
				}
				if(userInfostr) {
					 token = this.b64DecodeUnicode(Cookies.get("userInfostr"))
				}
				Cookies.set("token", token)
				this.isToken = true
			}
		}
		localStorage.setItem('na',location.href)
	}

	mounted() {
		// console.log(this.isToken)
	}

	b64DecodeUnicode(str: any) {
		return decodeURIComponent(
			atob(str)
				.split("")
				.map(function(c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join("")
		)
	}
	flashToken() {
		const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		if (isAndroid) {
			this.getAndroidToken()
		} else if (isiOS) {
			;(window as any).getIOSToken = this.getIOSToken
		}
	}
	//调用安卓的方法
	getAndroidToken() {
		// console.log(val);
		// sessionStorage.setItem("token", (window as any).eshebao.getAccessToken())
		if ((window as any).eshebao.getAccessToken() != "UnLogin") {
			Cookies.set("token", (window as any).eshebao.getAccessToken())
			this.isToken = true
		}
	}
	//ios调用的H5方法
	getIOSToken(val: string) {
		// console.log(val)
		// sessionStorage.setItem("token", val)
		if (val != "UnLogin") {
			Cookies.set("token", val)
			this.isToken = true
		}
	}
}
